export const applicationAssistantAuthorizedUsers = [
  'vgupta13@amgen.com',
  'achatt05@amgen.com',
  'svc-opsdct-dev@amgen.com',
  'ssharm56@amgen.com',
//   'aluce@amgen.com',
  'sbajpa01@amgen.com',
//   'sdapke@amgen.com',
  'ahaq@amgen.com',
  'akaul02@amgen.com',
  'tthakur@amgen.com',
  'rajat@amgen.com',
  'nkoppura@amgen.com',
  'dayang@amgen.com',
  'tchaud03@amgen.com',
  'vpatiyal@amgen.com',
//   'jhasso@amgen.com',
];
