import React, { useState } from 'react';
import ChatBot, {
  Button,
  useMessages,
  useFlow,
  usePaths,
  useChatHistory,
  useStyles,
} from 'react-chatbotify';
import {
  LikeOutlined,
  DislikeOutlined,
  LikeTwoTone,
  DislikeTwoTone,
  UndoOutlined,
  PlusCircleFilled,
  DoubleLeftOutlined,
  DoubleRightOutlined,
} from '@ant-design/icons';
import Joyride, { STATUS, ACTIONS, EVENTS } from 'react-joyride';
import { useNavigate } from 'react-router-dom';
import {
  message,
  Modal,
  Select,
  Button as FooterButton,
  Popover,
  Input,
  Space,
} from 'antd';
import { encrypt } from './secureLink';
import './index.scss';

function ReactChatbot() {
  const navigate = useNavigate();
  const [isJoyrideRunning, setIsJoyrideRunning] = useState(false);
  const [joyrideSteps, setJoyrideSteps] = useState([]);
  const [stepIndex, setStepIndex] = useState(0);
  const [urlSegments, setUrlSegments] = useState({
    path: '',
    targetElement: '',
  });
  const [answerReferences, setAnswerReferences] = useState([]);
  const [serviceNowLink, setServiceNowLink] = useState('');
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [intent, setIntent] = useState('');
  const [selectedOption, setSelectedOption] = useState('all');
  const [selectedSuggestion, setSelectedSuggestion] = useState({
    userInput: '',
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { injectMessage } = useMessages();
  const { restartFlow } = useFlow();
  const { goToPath } = usePaths();
  const { setHistoryMessages } = useChatHistory();
  const { updateStyles } = useStyles();

  const userInfo = JSON.parse(localStorage.getItem('dct-okta-token') || '{}');
  const userEmail = userInfo?.idToken?.claims?.email;
  const accessToken = userInfo?.accessToken?.accessToken;

  const startJoyride = () => {
    console.log(stepIndex);
    let newSteps =
      urlSegments?.targetElement?.length > 0 &&
      urlSegments?.targetElement?.map(element => {
        return {
          ...element,
          disableBeacon: true,
          target: element?.target?.includes('#')
            ? element?.target
            : `#${element?.target}`,
        };
      });
    setJoyrideSteps(newSteps || []);
    setStepIndex(0);
    setTimeout(() => {
      message.success('Navigated successfully.', 2);
      setIsJoyrideRunning(true);
    }, 5000);
  };

  const intentOptions = [
    {
      value: 'all',
      label: 'All',
    },
    {
      value: 'dynamic_navigation',
      label: 'Navigation',
    },
    {
      value: 'guided_tour',
      label: 'Guided Tour',
    },
    {
      value: 'structured_data',
      label: 'Data Q&A',
    },
    {
      value: 'unstructured_data',
      label: 'Business FAQs',
    },
    {
      value: 'sop_assistant',
      label: 'SOP Q&A',
    },
    {
      value: 'service_now',
      label: 'Service Now',
    },
  ];

  const handleButtonClick = () => {
    urlSegments?.path !== '' && navigate(`/${urlSegments?.path}`);
    message.loading('Please wait while we are navigating...', 4);
    urlSegments?.targetElement?.length > 0 && startJoyride();
  };

  const handleApplyFilters = () => {
    const encryptedFilter = encrypt(JSON.stringify(selectedFilters));
    const encodedFilter = encodeURIComponent(encryptedFilter);
    const newUrl = `/${urlSegments?.path}?filter=${encodedFilter}`;

    if (window.location.pathname !== `/${urlSegments?.path}`) {
      navigate(newUrl);
    } else {
      window.location.href = newUrl;
    }
    message.loading('Please wait while we are navigating...', 4);
    startJoyride();
  };

  const SelectIntentComponent = () => {
    const [selectedIntent, setSelectedIntent] = useState('all');
    const [dropdownKey, setDropdownKey] = useState(0);

    return (
      <Select
        key={dropdownKey}
        dropdownStyle={{ zIndex: 10001 }}
        style={{ width: '142px', marginRight: '10px', marginLeft: '10px' }}
        placeholder="Select Intent"
        options={intentOptions}
        value={selectedIntent}
        onChange={value => {
          setSelectedIntent(value);
          setDropdownKey(prevValue => prevValue + 1);
          setSelectedOption(value);
        }}
      />
    );
  };

  const ResizeComponent = () => {
    const [isWindowExpanded, setIsWindowExpanded] = useState(false);

    return !isWindowExpanded ? (
      <DoubleLeftOutlined
        key={'expandWindow'}
        style={{ cursor: 'pointer', fontSize: '26px' }}
        title="Expand Window"
        onClick={() => {
          setIsWindowExpanded(true);
          updateStyles({
            ...botStyles,
            chatWindowStyle: { width: '900px', height: '80%' },
          });
        }}
      />
    ) : (
      <DoubleRightOutlined
        key={'shrinkWindow'}
        title="Collapse Window"
        style={{ cursor: 'pointer', fontSize: '26px' }}
        onClick={() => {
          setIsWindowExpanded(false);
          updateStyles({
            ...botStyles,
            chatWindowStyle: { width: '700px', height: '80%' },
          });
        }}
      />
    );
  };

  const OptionsComponent = () => {
    const options = localStorage.getItem('followup_questions')
      ? JSON.parse(localStorage.getItem('followup_questions'))
      : [];
    return (
      options?.length > 0 && (
        <div className="d-flex flex-column">
          <div className="suggested-ques-label">SUGGESTED QUESTIONS</div>
          {options?.map((option, index) => (
            <div
              className="suggested-ques"
              key={index}
              onClick={() => {
                setSelectedSuggestion({ userInput: option });
                injectMessage(option, 'user');
                goToPath('answer_reply');
              }}
            >
              {option}
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                key={'sendIcon'}
                style={{ height: '26px', width: '26px' }}
              >
                <path
                  d="M12.0007 0.333336C5.58398 0.333336 0.333984 5.58334 0.333984 12C0.333984 18.4167 5.58398 23.6667 12.0007 23.6667C18.4173 23.6667 23.6673 18.4167 23.6673 12C23.6673 5.58334 18.4173 0.333336 12.0007 0.333336ZM16.3173 12.8167C15.8507 13.2833 15.1507 13.2833 14.684 12.8167L13.1673 11.3V15.5C13.1673 16.2 12.7007 16.6667 12.0007 16.6667C11.3007 16.6667 10.834 16.2 10.834 15.5V11.3L9.31732 12.8167C8.85065 13.2833 8.15065 13.2833 7.68398 12.8167C7.21732 12.35 7.21732 11.65 7.68398 11.1833L11.184 7.68334C11.6507 7.21667 12.3507 7.21667 12.8173 7.68334L16.3173 11.1833C16.784 11.65 16.784 12.35 16.3173 12.8167Z"
                  fill="#0063C3"
                />
              </svg>
            </div>
          ))}
        </div>
      )
    );
  };

  const InitialOptionsComponent = () => {
    const options = [
      'Provide walkthrough of application components',
      'Navigate to chart/functionality',
      'Raise a ServiceNow Ticket',
    ];
    return (
      <div className="d-flex flex-column">
        <div className="suggested-ques-label">SUGGESTED QUESTIONS</div>
        {options.map((option, index) => (
          <div
            className="suggested-ques"
            key={index}
            onClick={() => {
              setSelectedSuggestion({ userInput: option });
              injectMessage(option, 'user');
              goToPath('answer_reply');
            }}
          >
            {option}
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              key={'sendIcon'}
              style={{ height: '26px', width: '26px' }}
            >
              <path
                d="M12.0007 0.333336C5.58398 0.333336 0.333984 5.58334 0.333984 12C0.333984 18.4167 5.58398 23.6667 12.0007 23.6667C18.4173 23.6667 23.6673 18.4167 23.6673 12C23.6673 5.58334 18.4173 0.333336 12.0007 0.333336ZM16.3173 12.8167C15.8507 13.2833 15.1507 13.2833 14.684 12.8167L13.1673 11.3V15.5C13.1673 16.2 12.7007 16.6667 12.0007 16.6667C11.3007 16.6667 10.834 16.2 10.834 15.5V11.3L9.31732 12.8167C8.85065 13.2833 8.15065 13.2833 7.68398 12.8167C7.21732 12.35 7.21732 11.65 7.68398 11.1833L11.184 7.68334C11.6507 7.21667 12.3507 7.21667 12.8173 7.68334L16.3173 11.1833C16.784 11.65 16.784 12.35 16.3173 12.8167Z"
                fill="#0063C3"
              />
            </svg>
          </div>
        ))}
      </div>
    );
  };

  const RetryComponent = () => {
    return (
      <div
        style={{
          fontSize: '11px',
          fontWeight: 700,
          color: '#0063c3',
          marginLeft: '57px',
          marginTop: '5px',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <div
          className="d-flex"
          onClick={() => {
            setSelectedSuggestion({
              userInput: JSON.parse(localStorage.getItem('userInput')),
            });
            injectMessage(
              JSON.parse(localStorage.getItem('userInput')),
              'user',
            );
            goToPath('answer_reply');
          }}
        >
          RETRY PROMPT
          <UndoOutlined style={{ fontSize: '12px', marginLeft: '4px' }} />
        </div>
        {localStorage.getItem('sql_query') && (
          <Popover
            title="SQL Query"
            content={localStorage.getItem('sql_query')}
            trigger={'click'}
            overlayStyle={{
              maxWidth: '490px',
              zIndex: 10001,
            }}
            overlayInnerStyle={{ fontSize: '13px' }}
            placement="right"
          >
            <div style={{ marginLeft: '20px' }}>VIEW SQL QUERY</div>
          </Popover>
        )}
        {localStorage.getItem('confidence_score') &&
          JSON.parse(localStorage.getItem('confidence_score')) >= 0 && (
            <div style={{ marginLeft: '20px', color: 'green', cursor: 'text' }}>
              CONFIDENCE SCORE:&nbsp;
              {JSON.parse(localStorage.getItem('confidence_score'))?.toFixed(1)}
              %
            </div>
          )}
      </div>
    );
  };

  const botSettings = {
    isOpen: false,
    general: {
      primaryColor: '#0063c3',
      secondaryColor: '#0063c3',
    },
    chatWindow: {
      showScrollbar: true,
    },
    advance: {
      useAdvancedPaths: true,
    },
    botBubble: {
      showAvatar: true,
      dangerouslySetInnerHtml: true,
      avatar: '',
    },
    userBubble: {
      showAvatar: true,
    },
    header: {
      showAvatar: false,
      title: (
        <div className="d-flex align-items-center">
          <ResizeComponent key={'resizeWindow'} />
          <div style={{ marginLeft: '10px' }}>DCT AI Assistant</div>
        </div>
      ),
      buttons: [
        <SelectIntentComponent key={'intentSelect'} />,
        <PlusCircleFilled
          key={'syncString'}
          style={{ cursor: 'pointer', fontSize: '26px' }}
          onClick={() => {
            setIsModalOpen(true);
          }}
          title="New Chat"
        />,
        Button.CLOSE_CHAT_BUTTON,
      ],
    },
    tooltip: {
      mode: 'START',
      text: 'Questions about DCT? Get answers anytime with DCT AI Assistant!',
    },
    chatHistory: {
      storageKey: 'react-chatbot-history',
      maxEntries: 80,
    },
    notification: {
      showCount: false,
    },
    chatInput: {
      enabledPlaceholderText: 'Type your prompt here',
      sendButtonIcon: '',
    },
    chatButton: {
      icon: '',
    },
    footer: {
      buttons: [],
      text: 'Experimental feature. Do not use for decision making, and double-check any important information received.',
    },
  };

  const botStyles = {
    headerStyle: {
      fontWeight: 700,
      fontSize: '18px',
      backgroundImage: 'none',
      backgroundColor: '#152269',
    },
    chatInputAreaStyle: {
      overflow: 'auto',
      outline: 'none',
      padding: '0px',
      border: 'none',
      minHeight: '25px',
    },
    chatInputAreaFocusedStyle: {
      outline: 'none',
      boxShadow: 'none',
    },
    chatInputContainerStyle: {
      boxShadow: 'rgb(0, 99, 195) 0px 0px 5px',
      borderTop: 'none',
      padding: '7px 16px',
      margin: '10px 20px',
      borderRadius: '45px',
    },
    chatWindowStyle: {
      width: '700px',
      height: '80%',
    },
    botBubbleStyle: {
      backgroundColor: '#F7F7FC',
      color: 'black',
      fontSize: '13px',
      maxWidth: '93%',
      marginRight: '10px',
    },
    userBubbleStyle: {
      backgroundColor: '#E7F1FD',
      color: 'black',
      textAlign: 'left',
      fontSize: '13px',
      maxWidth: '95%',
    },
    botOptionStyle: {
      color: 'black',
      backgroundColor: '#f0f0f0',
      border: 'none',
      borderRadius: '0px',
      justifyContent: 'start',
    },
    botOptionHoveredStyle: {
      color: 'black',
      backgroundColor: '#f0f0f0',
      border: 'none',
      borderRadius: '0px',
      justifyContent: 'start',
    },
    chatButtonStyle: {
      height: '65px',
      width: '65px',
      backgroundColor: '#152269',
      backgroundImage: 'none',
    },
    notificationBadgeStyle: {
      height: '25px',
      width: '25px',
      padding: '4px 8px',
    },
    sendButtonStyle: {
      height: '32px',
      width: '32px',
      borderRadius: '40px',
    },
    sendIconStyle: {
      width: '100%',
      height: '100%',
      marginTop: '4px',
    },
    footerStyle: {
      padding: '0px 0px 6px 0px',
      borderTop: 'none',
      backgroundColor: '#ffffff',
      color: '#8c8c8c',
      justifyContent: 'center',
    },
  };

  const handleLikeDislikeClick = async isLike => {
    injectMessage(
      isLike
        ? 'Thank you for your valuable feedback!'
        : 'Sorry to hear about your experience. We are continuously working on improving it.',
    );
    const modifiedBody = {
      isLiked: isLike,
      question: JSON.parse(localStorage.getItem('userInput')) || '',
      user: userEmail,
      answer: JSON.parse(localStorage.getItem('userResponsePayload')) || {},
    };
    try {
      await fetch('https://api-genai-dev.dct.amgen.com/userResponse', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(modifiedBody),
      });
    } catch (error) {
      return 'Error occurred while fetching data';
    }
  };

  const handleSuggestionSubmit = async (isLike, feedback) => {
    const modifiedBody = {
      isLiked: isLike,
      question: JSON.parse(localStorage.getItem('userInput')) || '',
      user: userEmail,
      answer: JSON.parse(localStorage.getItem('userResponsePayload')) || {},
      suggestion: feedback,
    };
    try {
      await fetch('https://api-genai-dev.dct.amgen.com/userSuggestion', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(modifiedBody),
      });
      message.loading('Submitting your feedback...', 2).then(() => {
        message.success('Thank you for your feedback!', 2);
      });
    } catch (error) {
      return 'Error occurred while fetching data';
    }
  };

  const LikeDislikeComponent = () => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLiked, setIsLiked] = useState(false);
    const [isDisliked, setIsDisliked] = useState(false);
    const [feedbackSuggestion, setFeedbackSuggestion] = useState('');
    return (
      <div
        className="d-flex flex-column"
        style={{
          marginLeft: '50px',
          marginTop: '8px',
          background: 'rgba(0, 99, 195, 0.05)',
          minHeight: '42px',
          width: '355px',
          fontSize: '13px',
          padding: '10px 14px',
        }}
      >
        <div className="d-flex align-items-center">
          <div style={{ marginRight: '18px', fontWeight: 700 }}>Feedback</div>
          <div style={{ marginRight: '24px' }}>Was this answer helpful?</div>
          <button
            className="like-dislike-btn"
            disabled={isDisabled}
            onClick={() => {
              setIsDisabled(true);
              setIsLiked(true);
              handleLikeDislikeClick(true);
            }}
            style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
          >
            {isLiked ? (
              <LikeTwoTone className="like-btn" />
            ) : (
              <LikeOutlined className="like-btn" />
            )}
          </button>
          <button
            className="like-dislike-btn"
            disabled={isDisabled}
            onClick={() => {
              setIsDisabled(true);
              setIsDisliked(true);
              handleLikeDislikeClick(false);
            }}
            style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
          >
            {isDisliked ? (
              <DislikeTwoTone className="dislike-btn" />
            ) : (
              <DislikeOutlined className="dislike-btn" />
            )}
          </button>
        </div>
        {isDisabled && (
          <div style={{ marginTop: '10px' }}>
            Suggestion:
            <Space.Compact style={{ width: '100%' }}>
              <Input
                placeholder="Enter your suggestion here"
                value={feedbackSuggestion}
                onChange={event => {
                  setFeedbackSuggestion(event.target.value);
                }}
              />
              <FooterButton
                type="primary"
                onClick={() => {
                  handleSuggestionSubmit(isLiked, feedbackSuggestion);
                  setFeedbackSuggestion('');
                }}
                style={{
                  backgroundColor: '#0063C3',
                  pointerEvents: feedbackSuggestion ? 'auto' : 'none',
                }}
              >
                Submit
              </FooterButton>
            </Space.Compact>
          </div>
        )}
      </div>
    );
  };

  const ReferencesComponent = () =>
    answerReferences?.length > 0 &&
    intent !== 'ServiceNow Agent' &&
    intent !== 'Filter Agent' && (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: '49px',
          marginTop: '8px',
          width: '100%',
        }}
      >
        <div
          style={{
            background: '#F7F7FC',
            width: 'auto',
            maxWidth: '90%',
            color: 'black',
            borderRadius: '4px',
            padding: '12px 16px',
            fontSize: '13px',
          }}
        >
          <div>
            <b>Reference(s)</b>
          </div>
          <br />
          {answerReferences?.map((reference, index) => (
            <div
              key={index}
              dangerouslySetInnerHTML={{ __html: reference }}
            ></div>
          ))}
        </div>
      </div>
    );

  const LinkComponent = () => {
    return (
      urlSegments?.path !== '' &&
      selectedFilters?.length === 0 &&
      intent !== 'ServiceNow Agent' &&
      intent !== 'Structured_data' &&
      intent !== 'Filter Agent' && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '49px',
            marginTop: '8px',
            width: '100%',
          }}
        >
          <div
            style={{
              background: '#F7F7FC',
              width: 'auto',
              maxWidth: '90%',
              color: 'black',
              borderRadius: '4px',
              padding: '12px 16px',
              fontSize: '13px',
            }}
            // className="pulsate"
          >
            <div
              style={{
                color: '#0063C3',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => {
                handleButtonClick();
              }}
            >
              Click here to navigate
            </div>
          </div>
        </div>
      )
    );
  };

  const ApplyFiltersComponent = () => {
    return (
      selectedFilters?.length !== 0 &&
      (intent === 'Filter Agent' ||
        intent === 'Routing Agent' ||
        intent === 'Structured_data') && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '49px',
            marginTop: '8px',
            width: '100%',
          }}
        >
          <div
            style={{
              background: '#F7F7FC',
              width: 'auto',
              maxWidth: '90%',
              color: 'black',
              borderRadius: '4px',
              padding: '12px 16px',
              fontSize: '13px',
            }}
            // className="pulsate"
          >
            <div
              style={{
                color: '#0063C3',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => {
                handleApplyFilters();
              }}
            >
              Click here to apply Filters
            </div>
          </div>
        </div>
      )
    );
  };

  const ServiceNowComponent = () => {
    return (
      serviceNowLink !== '' &&
      intent === 'ServiceNow Agent' && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '49px',
            marginTop: '8px',
            width: '100%',
          }}
        >
          <div
            style={{
              background: '#F7F7FC',
              width: 'auto',
              maxWidth: '90%',
              color: 'black',
              borderRadius: '4px',
              padding: '12px 16px',
              fontSize: '13px',
            }}
            // className="pulsate"
          >
            <a
              href={serviceNowLink}
              target="_blank"
              rel="noreferrer"
              style={{ color: '#0063c3', textDecoration: 'underline' }}
            >
              Click here to create a ServiceNow ticket
            </a>
          </div>
        </div>
      )
    );
  };

  const fetchData = async params => {
    setSelectedSuggestion({ userInput: '' });
    localStorage.removeItem('followup_questions');
    localStorage.removeItem('userResponsePayload');
    localStorage.removeItem('userInput');
    const userHistory = {
      content: params.userInput,
      sender: 'user',
    };
    const updatedChatHistory = [
      ...(JSON.parse(localStorage.getItem('chatHistoryPayload')) || []),
      userHistory,
    ];
    localStorage.setItem(
      'chatHistoryPayload',
      JSON.stringify(updatedChatHistory),
    );
    const modifiedBody = {
      chat_history: updatedChatHistory,
      question: params.userInput,
      screen: window?.location?.pathname,
      question_type: selectedOption,
      user: userEmail,
    };
    try {
      const response = await fetch('https://api-genai-dev.dct.amgen.com/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(modifiedBody),
      });

      const data = await response.json();
      const selectedFilter = data?.selected_filters || [];
      setSelectedFilters(selectedFilter);
      localStorage.setItem('userInput', JSON.stringify(params.userInput));
      localStorage.setItem('userResponsePayload', JSON.stringify(data));
      const botHistory = {
        content: data?.answer,
        sender: 'bot',
      };
      localStorage.setItem(
        'chatHistoryPayload',
        JSON.stringify([...updatedChatHistory, botHistory]),
      );
      localStorage.setItem(
        'followup_questions',
        JSON.stringify(data?.followup_questions) || [],
      );
      const references = data?.references || [];
      setUrlSegments({
        path: data?.navigation_link?.screen || '',
        targetElement: data?.navigation_link?.functionality || [],
      });
      setAnswerReferences(references);
      localStorage.setItem('sql_query', data?.sql_query || '');
      localStorage.setItem(
        'confidence_score',
        JSON.stringify(data?.confidence_score) || '',
      );
      setServiceNowLink(data?.service_now_link || '');
      setIntent(data?.intent || '');
      return data?.answer
        ? data?.answer
        : 'There was a problem fetching the data. Please try again later.';
    } catch (error) {
      return 'Error occurred while fetching data';
    }
  };

  const botFlow = {
    start: {
      message:
        'Welcome to DCT AI Assistant! How can I help you today? Here are some quick suggestions:',
      component: <InitialOptionsComponent />,
      path: 'answer_reply',
    },
    answer_reply: {
      message: async params => {
        setServiceNowLink('');
        localStorage.setItem('sql_query', '');
        localStorage.setItem('confidence_score', '');
        setAnswerReferences([]);
        setUrlSegments({
          path: '',
          targetElement: [],
        });
        setSelectedFilters([]);
        setIntent('');
        localStorage.removeItem('followup_questions');
        if (
          selectedSuggestion?.userInput ===
          'Provide walkthrough of application components'
        ) {
          localStorage.setItem(
            'userInput',
            JSON.stringify(selectedSuggestion?.userInput),
          );
          const userHistory = {
            content: selectedSuggestion?.userInput,
            sender: 'user',
          };
          const botHistory = {
            content: 'Which tab would you like to have a walkthrough on?',
            sender: 'bot',
          };
          localStorage.setItem(
            'chatHistoryPayload',
            JSON.stringify([userHistory, botHistory]),
          );
          setSelectedSuggestion({ userInput: '' });
          localStorage.setItem('confidence_score', '');
          return '<div>Which tab would you like to have a walkthrough on?';
        } else if (
          selectedSuggestion?.userInput === 'Navigate to chart/functionality'
        ) {
          localStorage.setItem(
            'userInput',
            JSON.stringify(selectedSuggestion?.userInput),
          );
          const userHistory = {
            content: selectedSuggestion?.userInput,
            sender: 'user',
          };
          const botHistory = {
            content:
              'Which chart or functionality would you like to navigate to?',
            sender: 'bot',
          };
          localStorage.setItem(
            'chatHistoryPayload',
            JSON.stringify([userHistory, botHistory]),
          );
          setSelectedSuggestion({ userInput: '' });
          localStorage.setItem('confidence_score', '');
          return 'Which chart or functionality would you like to navigate to?';
        } else {
          const result = await fetchData(
            selectedSuggestion?.userInput !== '' ? selectedSuggestion : params,
          );
          return result;
        }
      },
      transition: { duration: 300 },
      component: <RetryComponent />,
      path: 'reference_component',
    },
    reference_component: {
      message: '',
      component: <ReferencesComponent />,
      transition: { duration: 300 },
      path: 'link_component',
    },
    link_component: {
      message: '',
      component: <LinkComponent />,
      transition: { duration: 300 },
      path: 'apply_filters',
    },
    apply_filters: {
      message: '',
      component: <ApplyFiltersComponent />,
      transition: { duration: 300 },
      path: 'servicenow_component',
    },
    servicenow_component: {
      message: '',
      component: <ServiceNowComponent />,
      transition: { duration: 300 },
      path: 'like_dislike_component',
    },
    like_dislike_component: {
      message: '',
      component: <LikeDislikeComponent />,
      path: 'options_component',
      transition: { duration: 300 },
    },
    options_component: {
      message: '',
      component: <OptionsComponent />,
      path: 'answer_reply',
    },
  };

  const handleJoyrideCallback = data => {
    const { action, type, status, index } = data;

    if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) ||
      (action === ACTIONS.CLOSE && type === EVENTS.STEP_AFTER)
    ) {
      setIsJoyrideRunning(false);
      setStepIndex(0);
    } else {
      setStepIndex(index + 1);
    }
  };

  return (
    <>
      <Modal
        style={{ top: '25%' }}
        styles={{ mask: { zIndex: 10001 }, wrapper: { zIndex: 10001 } }}
        title="Confirmation"
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        onClose={() => {
          setIsModalOpen(false);
        }}
        footer={[
          <FooterButton
            key="cancel"
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            Back to Chat
          </FooterButton>,
          <FooterButton
            key="submit"
            type="primary"
            onClick={() => {
              setIsModalOpen(false);
              localStorage.removeItem('react-chatbot-history');
              localStorage.removeItem('sql_query');
              localStorage.removeItem('confidence_score');
              setHistoryMessages([]);
              localStorage.removeItem('followup_questions');
              localStorage.removeItem('chatHistoryPayload');
              localStorage.removeItem('userResponsePayload');
              localStorage.removeItem('userInput');
              setSelectedSuggestion({ userInput: '' });
              restartFlow();
              setSelectedOption('all');
            }}
            style={{ backgroundColor: '#0063C3' }}
          >
            New Chat
          </FooterButton>,
        ]}
      >
        <div>
          You will lose information in your current chat. Are you sure you want
          to start a new chat?
        </div>
      </Modal>
      <ChatBot settings={botSettings} styles={botStyles} flow={botFlow} />
      <Joyride
        steps={joyrideSteps}
        run={isJoyrideRunning}
        continuous={true}
        scrollToFirstStep={true}
        showProgress={false}
        showSkipButton={true}
        callback={handleJoyrideCallback}
        hideCloseButton={true}
        disableCloseOnEsc={true}
        disableOverlayClose={true}
      />
    </>
  );
}

export default ReactChatbot;
